import { useState } from 'react';
import './App.css';
import POSApp from './POSApp';
import { Bars3Icon, UserIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';

function App() {
  const cashboxid = "f78f303c-a14c-4eed-a4c9-b80ff00c6023";
  const accesstoken = "BA6lFIUJhsU86zBGJ7328scdl+wkR1N74DzSHJaa7PmRnAEF3WHcMzglvP+lLKZMojc1cvKfe/CeO4Dk0/NkKxE=";
  const [version] = useState(0);

  let settings = {
    cashboxid: cashboxid,
    accesstoken: accesstoken
  };
  return (
    <div className="w-screen h-screen flex flex-col">
      <main className="flex justify-center overflow-auto h-full flex-col">
        <POSApp key={version} settings={settings} />
      </main>
    </div>
  );
}

export default App;

import { possettings } from "./models/possettings";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { ftQueueItem, ftReceiptJournal, journalAADE, journalQueueItems, journalReceiptJournals } from "./services/posapi";
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from "@tanstack/react-query";
import WaitDialog from "./dialogs/WaitDialog";
import ReceiptRequest from "./models/ReceiptRequest";
import ReceiptResponse from "./models/ReceiptResponse";
import { ComputerDesktopIcon, CloudIcon } from '@heroicons/react/20/solid'
import TableView from "./components/Table/View/TableView";
import styled from "styled-components";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const CSVDiv = styled.div`
    margin: 20px 0px 0px 20px;
`;
const Searchbar = styled.div`
margin-bottom:5px;
height:34px;
    &:hover,
    &:focus-within {
        border-color: #30add1;       
    }
`;
const Span = styled.span`
    font-weight: bold;
`;
const EmptyIcon = styled.i`
    padding-right: 3px;
`;

export interface TableColumnModel {
    id: string;
    title: string | JSX.Element | JSX.Element[];
    sortable?: boolean;
    textAlignment?:
    | "justify-content-center"
    | "justify-content-flex-start"
    | "justify-content-flex-end";
    allowOverflow?: boolean;
    columnClass?: string;
    columnStyle?: CSSProperties;
    render: (data: Receipt) => JSX.Element | JSX.Element[] | string;
}

interface InvoiceParsedResult {
    uid: string;
    mark: string;
    xmlPayload: string;
    issuerVat: string;
    counterPartVat: string;
    issueDate: string;
    transmissionFailure: string;
    qrCodeUrl?: string;
    invoiceNode: Element;
}
export type POSAppProps = {
    settings: possettings;
};



const POSApp = ({ settings }: POSAppProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [receiptLink, setReceiptLink] = useState<string>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const tableId = "receipttable";
    const queueitemsQuery = useQuery({
        queryKey: ['queueItems'], queryFn: async () => {
            const response = await journalQueueItems(settings.cashboxid, settings.accesstoken);
            return response as ftQueueItem[];
        }
    });

    const receiptJournalsQuery = useQuery({
        queryKey: ['receiptJournals'], queryFn: async () => {
            const response = await journalReceiptJournals(settings.cashboxid, settings.accesstoken);
            return response as ftReceiptJournal[];
        }
    });

    const aadeQuery = useQuery({
        queryKey: ['aade'], queryFn: async () => {
            const response = await journalAADE(settings.cashboxid, settings.accesstoken);
            return response;
        }
    });



    const headers: TableColumnModel[] = [
        {
            id: "description",
            title: "",
            sortable: true,
            textAlignment: "justify-content-flex-start",
            render: (item) => (
                <span
                    className={`test-data-queue-id`}
                >
                    {item.aadeData?.transmissionFailure == "1" ? <span className="inline-flex items-center rounded-md bg-red-500 px-2 py-1 font-medium text-white ring-1 ring-inset ring-gray-500/10">
                        Απώλεια Διασύνδεσης Οντότητας - Παρόχου
                    </span> : <></>}

                    {item.aadeData?.transmissionFailure == "2" ? <span className="inline-flex items-center rounded-md bg-red-500 px-2 py-1 font-medium text-white ring-1 ring-inset ring-gray-500/10">
                        Invoice Transmission Failure 2 - Απώλεια Διασύνδεσης Παρόχου – ΑΑΔΕ"
                    </span> : <></>}

                    {!item.aadeData?.transmissionFailure ? <span className="inline-flex items-center rounded-md bg-green-500 px-2 py-1 font-medium text-white ring-1 ring-inset ring-gray-500/10">
                        Επιτυχής αποστολή
                    </span> : <></>}
                </span>
            ),
        },
        {
            id: "Mark",
            title: "Μ.ΑΡ.Κ:",
            render: (item) => <span
                className={`test-data-queue-id`}
            >
                {item.aadeData.mark}
            </span>,
        },
        {
            id: "Αριθμός παραστατικού",
            title: "Αριθμός παραστατικού",
            textAlignment: "justify-content-center",
            render: (item) => <>{item.receiptResponse?.ftReceiptIdentification}</>,
        },
        {
            id: "journal",
            title: "ΑΦΜ πελάτη",
            textAlignment: "justify-content-center",
            render: (item) => <>{item.aadeData?.counterPartVat}</>,
        },
        {
            id: "export",
            title: "Ημερ/νια έκδοσης",
            textAlignment: "justify-content-center",
            render: (item) => <>{item.aadeData?.issueDate}</>,
        },
        {
            id: "actions",
            title: "",
            sortable: false,
            textAlignment: "justify-content-flex-end",
            render: (item) => <>
                <a target="_blank" className={`flex w-48 items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`} onClick={() => setReceiptLink(`https://receipts-sandbox.fiskaltrust.eu/${item.queueItem.ftQueueId}/${item.queueItem.ftQueueItemId}`)}>Εμφάνιση</a>
            </>,
        },
    ];

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.src = "about:blank";
            iframeRef.current.onload = () => {
                if (iframeRef.current!.src == 'about:blank') {
                    iframeRef.current!.src = `${receiptLink}?appId=${uuidv4()}`;
                }
            };
        }
    }, [receiptLink]);

    if (queueitemsQuery.isLoading) {
        return <WaitDialog show={true} message="Loading queueitems. .." />;
    }
    if (receiptJournalsQuery.isLoading) {
        return <WaitDialog show={true} message="Loading receiptJournalsQuery. .." />;
    }
    if (aadeQuery.isLoading) {
        return <WaitDialog show={true} message="Loading aadeQuery. .." />;
    }
    if (!aadeQuery.data) {
        return <WaitDialog show={true} message="Loading AADE..." />;
    }

    if (receiptJournalsQuery.data == undefined || queueitemsQuery.data == undefined || aadeQuery.data == undefined) {
        return <h1>Failed to load, please refresh</h1>
    }

    let receiptJournalsDict = receiptJournalsQuery.data.reduce((acc, receiptJournal) => {
        acc[receiptJournal.ftQueueItemId] = receiptJournal;
        return acc;
    }, {} as { [key: string]: ftReceiptJournal });
    let queueItemsDict = queueitemsQuery.data.reduce((acc, queueItem) => {
        acc[queueItem.ftQueueItemId] = queueItem;
        return acc;
    }, {} as { [key: string]: ftQueueItem });
    let parsedInvoices = parseInvoicesWithDOMParser(aadeQuery.data);
    let aadeItemsDict = parsedInvoices.reduce((acc, aadeItem) => {
        acc[aadeItem.mark] = aadeItem;
        return acc;
    }, {} as { [key: string]: InvoiceParsedResult });

    let receipts: Receipt[] = [];
    for (let i = 0; i < receiptJournalsQuery.data.length; i++) {
        let receiptJournal = receiptJournalsQuery.data[i];
        let queueItem = queueItemsDict[receiptJournal.ftQueueItemId];
        if (queueItem == undefined) {
            continue;
        }

        let receiptRequest = JSON.parse(queueItem.request) as ReceiptRequest;
        let receiptResponse = JSON.parse(queueItem.response) as ReceiptResponse;
        let marker = receiptResponse?.ftSignatures.find(x => x.Caption == "invoiceMark")?.Data;
        if (marker == undefined) {
            continue;
        }
        let markerReceipt = aadeItemsDict[marker];
        let receipt: Receipt = {
            receiptJournal: receiptJournal,
            queueItem: queueItem as ftQueueItem,
            receiptRequest: receiptRequest,
            receiptResponse: receiptResponse,
            aadeData: markerReceipt
        }
        receipts.push(receipt);
    }
    receipts = receipts.filter(
        (invoice) =>
            (!startDate || new Date(invoice.aadeData?.issueDate) >= new Date(startDate)) &&
            (!endDate || new Date(invoice.aadeData?.issueDate) <= new Date(endDate)) &&
            (invoice.aadeData?.mark.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.aadeData?.counterPartVat.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.aadeData?.issueDate.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    receipts = receipts.sort((a, b) => {
        if (a.receiptJournal.ftReceiptMoment > b.receiptJournal.ftReceiptMoment) {
            return -1;
        }
        if (a.receiptJournal.ftReceiptMoment < b.receiptJournal.ftReceiptMoment) {
            return 1;
        }
        return 0;
    });

    const downloadCommunication = async () => {
        const zip = new JSZip();
        var folderName = 'mydata_export';
        if (searchTerm) {
            folderName += "_searchterm_" + searchTerm;
        }
        if (startDate) {
            folderName += "_startdate_" + startDate;
        }
        if (endDate) {
            folderName += "_enddate_" + endDate;
        }

        let data = '<?xml version="1.0" encoding="UTF-8"?>\n';
        data += '<InvoicesDoc xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://www.aade.gr/myDATA/invoice/v1.0">\n';
        for (let i = 0; i < receipts.length; i++) {
            let receipt = receipts[i];
            data += receipt.aadeData.xmlPayload;
        }
        data += '</InvoicesDoc>';

        const folder = zip.folder(folderName)!;
        folder.file(`${folderName}.xml`, data!);
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, `${folderName}.zip`);
    };

    return (
        <>
            <div
                className="flex flex-grow h-full w-full "
                style={{ overflowY: "hidden", padding: "15px 10px 0px 10px" }}
            >
                <div className="ibox float-e-margins flex">
                    <div className="ibox-content tst-queue-list flex-col flex">
                        <SearchInput
                            searchTerm={searchTerm}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            endDate={endDate}
                            startDate={startDate}
                            setSearchTerm={setSearchTerm}
                            value={searchTerm}
                            placeholder={"Search table"}
                            onChange={(c: any) => { }}
                        >
                        </SearchInput >
                        <TableView
                            columns={headers}
                            hasDetails
                            data={receipts}
                            tableId={tableId}
                            renderDetails={renderDetails}
                            contentStyle={{ height: "max(calc(100vh - 490px), 310px)" }}
                            renderEmpty={<div>
                                <div className="single-table-cell">
                                    <EmptyIcon className="fa fa-search " />
                                    <Span>{`No results found with "${searchTerm}"`}</Span>
                                </div>
                            </div>}
                            displaySkeleton
                        />

                        <div className="row flex flex-shrink">
                            <CSVDiv>
                                <button
                                    className={`btn btn-primary btn-sm import
                                            }`}
                                    onClick={() => downloadCommunication()}
                                >
                                    <i
                                        className="fa fa-download"
                                    />&nbsp;&nbsp;{"Λήψη δεδομένων"}
                                </button>
                            </CSVDiv>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function renderDetails(item: Receipt): JSX.Element {
    return (
        <>
            <div className="ft-row-details-content h-screen flex flex-row" style={{ padding: "15px" }}>
                <Timeline queueItem={item.queueItem} receiptRequest={item.receiptRequest} receiptResponse={item.receiptResponse} />
                <iframe src={`https://receipts-sandbox.fiskaltrust.eu/${item.queueItem.ftQueueId}/${item.queueItem.ftQueueItemId}`} className="w-full h-full" />
            </div>
        </>
    );
}


const parseInvoicesWithDOMParser = (xmlString: string): InvoiceParsedResult[] => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    const invoices = Array.from(xmlDoc.getElementsByTagName('invoice'));

    return invoices.map((invoiceNode) => {
        const uid = invoiceNode.querySelector('uid')?.textContent || '';
        const mark = invoiceNode.querySelector('mark')?.textContent || '';
        const qrCodeUrl = invoiceNode.querySelector('qrCodeUrl')?.textContent || '';
        const transmissionFailure = invoiceNode.querySelector('transmissionFailure')?.textContent || '';
        const xmlPayload = invoiceNode.outerHTML; // Get the XML payload as a string

        // Parse issuer VAT number and issue date
        const issuerVat = invoiceNode.querySelector('issuer > vatNumber')?.textContent || '';
        const counterPartVat = invoiceNode.querySelector('counterpart > vatNumber')?.textContent || '';
        const issueDate = invoiceNode.querySelector('invoiceHeader > issueDate')?.textContent || '';

        return { uid, mark, xmlPayload, issuerVat, counterPartVat, issueDate, transmissionFailure: transmissionFailure, qrCodeUrl, invoiceNode };
    });
};


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export type TimelineProps = {
    queueItem: ftQueueItem;
    receiptResponse: ReceiptResponse;
    receiptRequest: ReceiptRequest;
};
const Timeline = ({ queueItem, receiptRequest, receiptResponse }: TimelineProps) => {
    const timeline = [
        {
            id: 1,
            content: 'Δημιουργία',
            target: 'παραστατικού',
            href: '#',
            date: new Date(receiptRequest.cbReceiptMoment ?? new Date()).toDateString(),
            datetime: `${new Date(receiptRequest.cbReceiptMoment ?? new Date()).getHours().toString().padStart(2, '0')}:${new Date(receiptRequest.cbReceiptMoment ?? new Date()).getMinutes().toString().padStart(2, '0')}`,
            icon: ComputerDesktopIcon,
            iconBackground: 'bg-gray-400',
        }
    ];
    timeline.push(
        {
            id: 2,
            content: 'Αποστολή',
            target: 'παραστατικού προς myData',
            href: '#',
            date: new Date(receiptResponse.ftReceiptMoment).toDateString(),
            datetime: `${new Date(receiptResponse.ftReceiptMoment).getHours().toString().padStart(2, '0')}:${new Date(receiptResponse.ftReceiptMoment).getMinutes().toString().padStart(2, '0')}`,
            icon: CloudIcon,
            iconBackground: 'bg-gray-400',
        });
    if ((BigInt(receiptRequest.ftReceiptCase) & BigInt(0x0000_0000_0001_0000)) == BigInt(0x0000_0000_0001_0000)) {

    }

    /**
     * Please select a receipt -> Παρακαλώ διαλέξτε απόδειξη ή αναζητήστε στα αριστερά
Connection case (normal with no connection loss)
Timestamp Creation Cash Register  -> Δημιουργία παραστατικού
Timestamp Middleware Middleware -> Αποστολή παραστατικού προς myData
Connection loss case
Timestamp Creation Cash Register  -> Δημιουργία παραστατικού
Timestamp Middleware Middleware -> Αποστολή παραστατικού προς myData
Preloaded receipt case
Timestamp Creation Cash Register  -> Δημιουργία παραστατικού
Timestamp Middleware Middleware -> Αποστολή παραστατικού προς myData
When new invoice created -> Ενημέρωση παραστατικού
Timestamp Middleware Middleware (for updated invoice) -> Αποστολή ενημέρωσης παραστατικού προς myData
     */

    return (
        <div className="flow-root self-start p-5">
            <ul role="list" className="-mb-8">
                {timeline.map((event, eventIdx) => (
                    <li key={event.id}>
                        <div className="relative pb-8">
                            {eventIdx !== timeline.length - 1 ? (
                                <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                            ) : null}
                            <div className="relative flex space-x-3">
                                <div>
                                    <span
                                        className={classNames(
                                            event.iconBackground,
                                            'flex size-8 items-center justify-center rounded-full ring-8 ring-white',
                                        )}
                                    >
                                        <event.icon aria-hidden="true" className="size-5 text-white" />
                                    </span>
                                </div>
                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                    <div>
                                        <p className="text-gray-500">
                                            {event.content}{' '}
                                            <a href={event.href} className="font-medium text-gray-900">
                                                {event.target}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="whitespace-nowrap text-right text-gray-500">
                                        <time dateTime={event.datetime}>{event.date} {event.datetime}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export interface Receipt {
    receiptJournal: ftReceiptJournal;
    queueItem: ftQueueItem;
    receiptResponse: ReceiptResponse,
    receiptRequest: ReceiptRequest,
    aadeData: InvoiceParsedResult;
}

export default POSApp;

interface IIconFigure {
    iconClass: string;
    title: string | JSX.Element;
    iconSize?: string;
    content?: JSX.Element | string;
    isLoading?: boolean;
}

export const InlineSpinner: React.FC<{ className?: string; style?: React.CSSProperties }> = (
    props
) => (
    <div style={props.style} className={`spin-element ${props.className ?? ""}`}>
        <div className="sk-spinner sk-spinner-three-bounce">
            <div className="sk-bounce1" />
            <div className="sk-bounce2" />
            <div className="sk-bounce3" />
        </div>
    </div>
);




export interface ISearchInputSettings {
    ariaLable?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    defaultValue?: string;
    searchTerm?: string;
    setSearchTerm: (s: string) => void;
    startDate: string;
    endDate: string;
    setStartDate: (s: string) => void;
    setEndDate: (s: string) => void;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
}
const SearchInput: React.FC<ISearchInputSettings> = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const formatDate = (date: string) => {
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate()}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const reset = () => {
        setStartDate('');
        setEndDate('');
        setSearchTerm('');
        props.setStartDate('');
        props.setEndDate('');
        props.setSearchTerm('');
    };

    const filter = () => {
        props.setStartDate(startDate);
        props.setEndDate(endDate);
        props.setSearchTerm(searchTerm);
    };

    return (<>
        <div>
            <Searchbar className="search-bar white-search-bar search-pmb-0 min-h-12">
                <div className="search-bar--list">
                    <span className="fa fa-search search-ml-7" />
                    <input
                        ref={props.inputRef}
                        aria-label={props.ariaLable}
                        type="search"
                        defaultValue={props.defaultValue}
                        value={searchTerm}
                        autoComplete="off"
                        placeholder="Μ.ΑΡ.Κ, ΑΦΜ ή ημερ/νια"
                        className={"text-filterbaritem-input textfield-input flex-grow"}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="flex gap-4 mb-4">
                    <input
                        type="date"
                        value={startDate ? formatDate(startDate) : ''}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="flex-grow p-2 border rounded-md text-black"
                    />
                    <input
                        type="date"
                        value={endDate ? formatDate(endDate) : ''}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="flex-grow p-2 border rounded-md text-black"
                    />
                </div>
            </Searchbar>
            <button
                onClick={() => filter()}
                className={`btn btn-primary btn-sm import
                }`}
            >
                <i
                    className="fa fa-download"
                />&nbsp;&nbsp;{"Φίλτρο"}
            </button>
            <button
                onClick={() => reset()}
                className={`btn btn-primary btn-sm import ml-5
                }`}
            >
                <i
                    className="fa fa-download"
                />&nbsp;&nbsp;{"Reset"}
            </button>
        </div>
    </>
    );
}

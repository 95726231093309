import React, { CSSProperties, useState } from "react";
import { observer } from "mobx-react-lite";
import "./TableView.css";
import { Receipt, TableColumnModel } from "../../../POSApp";

interface ITableViewProps {
    data: Receipt[];
    hasDetails: boolean;
    columns: TableColumnModel[];
    tableId: string;
    renderDetails?: (data: Receipt) => JSX.Element | JSX.Element[];
    renderEmpty: React.ReactNode;
    contentStyle?: CSSProperties;
    displaySkeleton?: boolean;
    skeletonRowCount?: number;
    disableHoverEffect?: boolean;
}

interface IRowState {
    showDetails: boolean;
}

const TableView = (props: ITableViewProps) => {
    const [rowStates, setRowStates] = useState(new Map<string, IRowState>());
    const isEmpty = props.data?.length == 0;

    return (
        <div key={props.tableId} className="ft-table-content flex flex-grow">
            <div className="table-responsive">
                <table
                    className={`table-striped ${props.disableHoverEffect ? "" : "table-hover"} toggle-arrow-tiny ${isEmpty ? "ft-table" : "table"
                        }`}
                >
                    <thead className="thead-light sticky-table-header">
                        <tr>
                            {props.hasDetails && <th key="-99" />}
                            {props.columns.map((column, cellId) => (
                                <th
                                    key={cellId}
                                    className={`${column.textAlignment && column.textAlignment}`}
                                    style={column.sortable ? { ...column.columnStyle, cursor: "pointer" } : { ...column.columnStyle, cursor: "default" }}
                                >
                                    {column.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isEmpty ? (
                            <>
                                <tr className="single-table-row">
                                    <td colSpan={999}>
                                        <div className="single-table-cell">
                                            {props.renderEmpty}
                                        </div>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            getRows(props.data ?? [])
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );



    function getRows(displayedData: Receipt[]) {
        return (
            <>
                {props.hasDetails
                    ? displayedData.map((rowData, cellId) => getRowWithDetails(rowData, cellId))
                    : displayedData.map((rowData, cellId) => getRow(rowData, cellId))}
            </>
        );
    }

    function getRow(rowData: Receipt, cellId: number) {
        return (
            <tr
                className="test-main-row"
                key={cellId}
            >
                {props.columns.map((x, tdId) => (
                    <td
                        className={`${x.textAlignment || ""} ${x.columnClass || ""}`}
                        key={tdId}
                        style={{ verticalAlign: "middle" }}
                    >
                        {x.render(rowData)}
                    </td>
                ))}
            </tr>
        );
    }

    function toggleDetails(rowId: string) {
        const newRowStates = new Map(rowStates);

        const rowState = newRowStates.get(rowId);
        if (rowState) {
            newRowStates.delete(rowId);
        } else {
            const addedRowState: IRowState = { showDetails: true };
            newRowStates.set(rowId, addedRowState);
        }
        setRowStates(newRowStates);
    }

    function getRowWithDetails(rowData: Receipt, cellId: number) {
        const rowId = rowData.queueItem.ftQueueItemId;
        const rowState = rowStates.get(rowId);
        const showDetails = rowState && rowState.showDetails;

        return (
            <React.Fragment key={`${cellId}-details-fragment`}>
                <tr
                    key={`${cellId}-details`}
                    data-toggle="collapse"
                    data-target={`#${rowId}`}
                    className={`test-detail-row accordion-toggle row-clickable ${!showDetails && "collapsed"
                        }`}
                    area-expanded={`${showDetails}`}
                    onClick={() => toggleDetails(rowId)}
                >
                    <td style={{ verticalAlign: "middle", width: "10px" }}>
                        <i className="fa fa-chevron-down" />
                    </td>
                    {props.columns.map((x, tdId) => (
                        <td
                            className={`${x.textAlignment && x.textAlignment}`}
                            key={tdId}
                            style={{ verticalAlign: "middle" }}
                        >
                            {x.render(rowData)}
                        </td>
                    ))}
                </tr>
                {showDetails && (
                    <tr key={`${cellId}-details-collapse`}>
                        <td
                            colSpan={props.columns.length + 1}
                            style={{ padding: "0px", border: "0px" }}
                        >
                            <div id={rowId}>{props.renderDetails && props.renderDetails(rowData)}</div>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }
};
export default observer(TableView);